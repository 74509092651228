import React, { useEffect } from "react";
import { fetchUsers } from "../User/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Spinner, Table } from "react-bootstrap";
import { deleteSchool, fetchSchools } from "./schoolThunk";
import { useNavigate } from "react-router-dom";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";

const sampleCountries = [
  { name: "United States", isEnabled: true, school: "Harvard University" },
  { name: "Canada", isEnabled: true, school: "University of Toronto" },
  { name: "United Kingdom", isEnabled: false, school: "University of Oxford" },
];

export default function Schools() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { schools, status, error } = useSelector((state) => state.schools);
  useEffect(() => {
    dispatch(fetchSchools());
  }, []);

  const removeItem = async (_id) => {
    try {
      await dispatch(deleteSchool(_id));
      dispatch(fetchSchools());
    } catch (error) {
      console.error("Failed to remove item:", error);
    }
  };
  return (
    <div>
      <div>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h1>Schools</h1>

          <button className="button"   onClick={() => navigate("/add-school")}>
            Add School
          </button>
        </div>
      </div>
      <Card className="mt-4">
        <Card.Header>
          <h5>Schools Overview</h5>
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Address</th>
                <th>Country</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {status === "loading" && (
                <div className="loader-container">
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
              {status === "failed" && <p>Error: {error}</p>}
              {status === "succeeded" &&
                schools?.schools?.map((item, i) => (
                  <tr key={i + 1}>
                    <td>{i + 1}</td>
                    <td>{`${item.name}`}</td>
                    <td>{`${item.address}`}</td>
                    <td>{`${item.country?.name}`}</td>
                    <td>{item.isEnabled ? "Active" : "Inactive"}</td>

                    <td>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          cursor: "pointer",
                        }}
                      >
                        <FaEdit
                          onClick={() => {
                            navigate("/add-school", { state: item });
                          }}
                        />
                        {/* <FaEye /> */}
                        <FaTrash onClick={() => removeItem(item._id)} />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      {/* )} */}
    </div>
  );
}
