import React, { useEffect, useState } from "react";
import "./Countries.css"; // Make sure to create this CSS file for styling
import {
  Button,
  Card,
  Container,
  Form,
  Modal,
  Spinner,
  Table,
} from "react-bootstrap";
import { fetchUsers } from "../User/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { deleteCountries, fetchCountries } from "./countryThunk";
import { useNavigate } from "react-router-dom";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";

const Countries = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { countries, status, error } = useSelector((state) => state.countries);
  const [show, setShow] = useState(false);
  const [info, setInfo] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (status === "idle") {
      dispatch(
        fetchCountries({ search, skip: currentPage, limit: itemsPerPage })
      );
    }
  }, [dispatch, currentPage, search, itemsPerPage]);
  const removeCountry = async (_id) => {
    try {
      await dispatch(deleteCountries(_id));
      dispatch(
        fetchCountries({ search, skip: currentPage, limit: itemsPerPage })
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  const totalItems = countries?.total || 0;
  const totalPages = totalItems > 0 ? Math.ceil(totalItems / itemsPerPage) : 0;
  return (
    <div className="country">
      <div>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h1>Countries</h1>
          {/* <Button variant="primary" onClick={() => navigate("/add-country")}>
            Add Country
          </Button> */}

          <button className="button" onClick={() => navigate("/add-country")}>
            <span>Add Country</span>
          </button>
        </div>
      </div>

      <Card className="mt-4">
        <Card.Header>
          <h5>Countries Overview</h5>
          <div style={{ width: "270px" }}>
            <Form.Control
              onChange={handleSearch}
              value={search}
              size="lg"
              type="text"
              placeholder="Search here"
            />
          </div>
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {status === "loading" && (
                <div className="loader-container">
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
              {status === "succeeded" &&
                countries?.map((item, i) => (
                  <tr key={i + 1}>
                    <td>{i + 1}</td>
                    <td>{`${item.name}`}</td>
                    <td>{item.isEnabled ? "Active" : "Inactive"}</td>

                    <td>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          cursor: "pointer",
                        }}
                      >
                        <FaEdit
                          onClick={() => {
                            navigate("/add-country", { state: item });
                          }}
                        />
                        <FaEye
                          onClick={() => {
                            setShow(true);
                            setInfo(item);
                          }}
                        />
                        <FaTrash onClick={() => removeCountry(item._id)} />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>

          {/* Pagination Controls */}
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                <a
                  className="page-link"
                  href="#"
                  aria-label="Previous"
                  onClick={(e) => {
                    e.preventDefault();
                    if (currentPage > 1) {
                      handlePageChange(currentPage - 1);
                    }
                  }}
                >
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              {[...Array(totalPages)?.keys()]?.map((page) => (
                <li
                  key={page + 1}
                  className={`page-item ${
                    currentPage === page + 1 ? "active" : ""
                  }`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handlePageChange(page + 1);
                    }}
                  >
                    {page + 1}
                  </a>
                </li>
              ))}
              <li className="page-item">
                <a
                  className="page-link"
                  href="#"
                  aria-label="Next"
                  onClick={(e) => {
                    e.preventDefault();
                    if (currentPage < totalPages) {
                      handlePageChange(currentPage + 1);
                    }
                  }}
                >
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </Card.Body>
      </Card>
      <div>
        <Modal show={show} onHide={() => setShow(false)} centered>
          <Modal.Header closeButton className="bg-primary text-white">
            <Modal.Title>Country Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <div className="mb-3">
                <h5 className="text-primary">Name:</h5>
                <p>{info.name}</p>
              </div>
              <div>
                <h5 className="text-primary">Status:</h5>
                <p className={info.isEnabled ? "text-success" : "text-danger"}>
                  {info.isEnabled ? "Active" : "Inactive"}
                </p>
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button variant="secondary" onClick={() => setShow(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={() => setShow(false)}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Countries;
