import { createAsyncThunk } from "@reduxjs/toolkit";
import { base_url } from "../../constants";
import axios from "axios";
export const login = createAsyncThunk(
  "auth/login",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${base_url}/login`, credentials, {
        headers: {
          "Content-Type": "application/json",
        },
      }); 
      return response.data;
    } catch (error) { 
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (data, { rejectWithValue } ) => {
    let token = localStorage.getItem("token"); 
    try { 
      const response = await axios.post(
        `${base_url}/change_password`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            token: `${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response? error.response.data : error.message
      );
    }
  }
)

export const forgotPassword = createAsyncThunk(
  "auth/changePassword",
  async (data, { rejectWithValue } ) => {
    let token = localStorage.getItem("token"); 
    try { 
      const response = await axios.post(
        `${base_url}/forgotPassword`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            token: `${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response? error.response.data : error.message
      );
    }
  }
)