import React from 'react';
import { Nav } from 'react-bootstrap'; // Ensure you have react-bootstrap installed
import { Link } from 'react-router-dom';
import { FaHome, FaUsers, FaGlobe, FaSchool, FaUserGraduate, FaQuestion, FaUserCircle, FaLock, FaCircleNotch, FaAddressBook, FaSignOutAlt } from 'react-icons/fa';
import './sidebar.css'; // Import your CSS file
import logo from "../../assets/img/logo_white.png"

const Sidebar = () => {
    const logout = () => {
        // Add your logout logic here
        localStorage.removeItem('token'); // Clear the token from local storage
        window.location.href = '/login'; // Redirect to the login page
    }
    return (
        <div className="sidebar" style={{ overflow: 'auto' }}>
            <img src={logo} alt="Logo" className="sidebar-logo" />
            <Nav className="flex-column">
                <Nav.Link as={Link} to="/dashboard" className="nav-link">
                    <FaHome className="nav-icon" /> Dashboard
                </Nav.Link>
                <Nav.Link as={Link} to="/users" className="nav-link">
                    <FaUsers className="nav-icon" /> Users
                </Nav.Link>
                <Nav.Link as={Link} to="/countries" className="nav-link">
                    <FaGlobe className="nav-icon" /> Countries
                </Nav.Link>
                <Nav.Link as={Link} to="/schools" className="nav-link">
                    <FaSchool className="nav-icon" /> Schools
                </Nav.Link>
                <Nav.Link as={Link} to="/grade" className="nav-link">
                    <FaUserGraduate className="nav-icon" /> Grades
                </Nav.Link>
                <Nav.Link as={Link} to="/questions" className="nav-link">
                    <FaQuestion className="nav-icon" /> Questions
                </Nav.Link>
                <Nav.Link as={Link} to="/admin-profile" className="nav-link">
                    <FaUserCircle className="nav-icon" /> Profile
                </Nav.Link>
                <Nav.Link as={Link} to="/change-password" className="nav-link">
                    <FaLock className="nav-icon" /> Change Password
                </Nav.Link>
                <Nav.Link as={Link} to="/faq-management" className="nav-link">
                    <FaCircleNotch className="nav-icon" /> FAQs
                </Nav.Link>
                <Nav.Link as={Link} to="/about" className="nav-link">
                    <FaAddressBook className="nav-icon" /> About
                </Nav.Link>
                <Nav.Link as={Link} to="/privacy-policy" className="nav-link">
                    <FaAddressBook className="nav-icon" /> Privacy Policy
                </Nav.Link>
                <Nav.Link as={Link} to="/T&C" className="nav-link">
                    <FaAddressBook className="nav-icon" /> Terms & Conditions
                </Nav.Link>
                <Nav.Link as={Link} className="nav-link" onClick={logout}>
                    <FaSignOutAlt className="nav-icon" /> Logout
                </Nav.Link>
            </Nav>
        </div>
    );
};

export default Sidebar;