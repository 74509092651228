import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import "./content.css"; // Import the CSS file
import { base_url } from "../../constants";

export default function About() {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(true); // Loading state
  const navigate = useNavigate();

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      ["link", "image"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["clean"], // remove formatting button
    ],
  };

  const loadList = async () => {
    setLoading(true); // Start loading
    try {
      const res = await axios.get(base_url+"/contents/3", {
        headers: { token: localStorage.getItem("token") },
      });
      if (res.status === 200) {
        setValue(res?.data?.data?.content);
      } else if (res.data.code === 203) {
        navigate("/");
        toast.info("Session Expired. Please log in again!");
      }
    } catch (err) {
      console.error("Error loading content:", err);
      toast.error("Failed to load content. Please try again.");
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleSubmit = async () => {
    try {
      const res = await axios.post(
        "http://localhost:3000/admin/v1/addContentPages",
        { type: 3, content: value },
        { headers: { token: localStorage.getItem("token") } }
      );

      if (res.status === 200) {
        toast.success("About content updated successfully!");
      } else {
        toast.error("Failed to update About content.");
      }
    } catch (err) {
      console.error("Error updating content:", err);
      toast.error("Failed to update content. Please try again.");
    }
  };

  useEffect(() => {
    loadList();
  }, []);

  return (
    <div className="container">
      <h1 className="header">About</h1>
      {loading ? (
   <div className="spinner-container">
   <div className="dot-spinner">
     <div className="dot-spinner__dot"></div>
     <div className="dot-spinner__dot"></div>
     <div className="dot-spinner__dot"></div>
     <div className="dot-spinner__dot"></div>
     <div className="dot-spinner__dot"></div>
     <div className="dot-spinner__dot"></div>
     <div className="dot-spinner__dot"></div>
     <div className="dot-spinner__dot"></div>
   </div>
 </div>
      ) : (
        <>
          <ReactQuill
            value={value}
            onChange={setValue}
            modules={modules}
            theme="snow"
          />
        </>
      )}
      {!loading && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <button className="submit-button" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      )}

      <div className="footer">© 2024 Your Company. All rights reserved.</div>
    </div>
  );
}
