import React, { useEffect } from "react";
import { useFormik } from "formik";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import "./FAQ.css";
import { addFAQ, fetchFAQs, updateFAQ } from "./faqThunk";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export default function AddFAQ() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // Formik setup
  const formik = useFormik({
    initialValues: {
      question: "",
      answer: "",
      _id: "",
    },
    onSubmit: async (values) => {
      try {
        if (values._id) {
          values._id = location.state._id;
          // Dispatch updateFAQ action with form values
          await dispatch(updateFAQ(values));
        } else {
          // Dispatch addFAQ action with form values
          await dispatch(addFAQ(values));
          await dispatch(fetchFAQs());
        }
        navigate("/faq-management");
      } catch (error) {
        console.error("Failed to submit form:", error);
      }
    },
  });

  useEffect(() => {
    if (location?.state) {
      const { question, answer, _id } = location.state;
      if (question) formik.setFieldValue("question", question);
      if (answer) formik.setFieldValue("answer", answer);
      if (_id) formik.setFieldValue("_id", _id);
    }
  }, [location.state]);

  return (
    <Container className="faq-container mt-5">
    <Row>
      <Col md={6} className="mx-auto">
        <h2 className="text-center"> {location?.state?._id ? "Edit":"Add"} FAQ</h2>
        <Form onSubmit={formik.handleSubmit} className="custom-form">
          <Form.Group controlId="question">
            <Form.Label>Question</Form.Label>
            <Form.Control
              type="text"
              name="question"
              onChange={formik.handleChange}
              value={formik.values.question}
              placeholder="Enter the question"
              required
              className="form-input"
            />
          </Form.Group>
          <Form.Group controlId="answer">
            <Form.Label>Answer</Form.Label>
            <Form.Control
              as="textarea"
              name="answer"
              onChange={formik.handleChange}
              value={formik.values.answer}
              placeholder="Enter the answer"
              required
              className="form-textarea"
            />
          </Form.Group>
          <Button className="mt-2" variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Col>
    </Row>
  </Container>
  );
}
