// src/components/Login.js
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./Login.css";
import logo from "../../assets/img/logo_black.png";
import { useDispatch, useSelector } from "react-redux";
import { login } from "./authThunk";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";

// Define validation schema using Yup
const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  password: Yup.string().required("Required"),
});

export default function Login() {
  const dispatch = useDispatch();
  const { user, status, error } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  // Initialize Formik
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const res = await dispatch(login(values));
        if (res?.payload?.code === 200) {
          setIsLoading(false);
          navigate("/dashboard");
        } else {
          setIsLoading(false);
          toast.error("Login failed. Please try again.");
        }
      } catch (error) {
        setIsLoading(false);
        console.error(error);
        toast.error("An error occurred. Please try again later.");
      }
    },
  });

  return (
    <div>
      <main className="form-signin">
        <form onSubmit={formik.handleSubmit}>
          <img className="mb-4" src={logo} alt="" width="70" height="70" />
          <h1 className="h3 mb-3 fw-normal">Sign in</h1>

          <div className="form-floating">
            <input
              type="email"
              name="email"
              className={`form-control ${
                formik.touched.email && formik.errors.email ? "is-invalid" : ""
              }`}
              id="floatingInput"
              placeholder="name@example.com"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            <label htmlFor="floatingInput">Email address</label>
            {formik.touched.email && formik.errors.email ? (
              <div className="invalid-feedback">{formik.errors.email}</div>
            ) : null}
          </div>

          <div className="form-floating">
            <input
              type="password"
              name="password"
              className={`form-control ${
                formik.touched.password && formik.errors.password
                  ? "is-invalid"
                  : ""
              }`}
              id="floatingPassword"
              placeholder="Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            <label htmlFor="floatingPassword">Password</label>
            {formik.touched.password && formik.errors.password ? (
              <div className="invalid-feedback">{formik.errors.password}</div>
            ) : null}
          </div>

          <div className="checkbox mb-3">
            <label>
              <input
                type="checkbox"
                name="rememberMe"
                checked={formik.values.rememberMe}
                onChange={formik.handleChange}
              />{" "}
              Remember me
            </label>
          </div>

          <button className=" button" type="submit">
            {isLoading ? "Signing..." : "Sign in"}
          </button>
        </form>
      <div className="text-center">
        <a
          className="link-secondary"
          style={{ cursor: "pointer", textDecoration: "none" }}
          onClick={() => setShow(true)}
        >
          Forgot password?
        </a>
      </div>
      </main>
      <Modal show={show}>
        <Modal.Dialog>
          <Modal.Header closeButton>
            <Modal.Title>Forgot Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mt-3"></div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="button"
              onClick={() => setShow(false)}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal.Dialog>
      </Modal>
    </div>
  );
}
