import React from "react";
import { useLocation, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import Login from "./components/Login/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import User from "./components/User/User";
import { Toaster } from "react-hot-toast";
import Profile from "./components/Admin/Profile";
import Countries from "./components/Countries/Countries";
import Schools from "./components/School/Schools";
import Grades from "./components/Grades/Grades";
import AddGrade from "./components/Grades/AddGrade";
import AddCountry from "./components/Countries/Add";
import AddSchool from "./components/School/Add";
import UpdateUser from "./components/User/UpdateUser";
import ChangePassword from "./components/Login/ChangePassword";
import Questions from "./components/Questions/Questions";
import AddQue from "./components/Questions/AddQue";
import FAQ from "./components/FAQ/FAQ";
import AddFAQ from "./components/FAQ/AddFaqs";
import PrivacyPolicy from "./components/Content/PrivacyPolicy";
import TAndC from "./components/Content/TAndC";
import About from "./components/Content/About";
import Sidebar from './components/Sidebar/Sidebar';

// Component to conditionally render Sidebar
const MainLayout = () => {
  const location = useLocation();
  let isLoggedIn = localStorage.getItem("token");
  const isLoginPage = location.pathname === "/login";

  return (
    <div className="d-flex">
      {!isLoginPage && <Sidebar />}
      <div
        className="flex-grow-1"
        style={{ marginLeft: isLoginPage ? "0" : "250px" }}
      >
        <div className="container mt-4">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/users"
              element={
                <ProtectedRoute>
                  <User />
                </ProtectedRoute>
              }
            />
            <Route
              path="/edit"
              element={
                <ProtectedRoute>
                  <UpdateUser />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin-profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/countries"
              element={
                <ProtectedRoute>
                  <Countries />
                </ProtectedRoute>
              }
            />
            <Route
              path="/add-country"
              element={
                <ProtectedRoute>
                  <AddCountry />
                </ProtectedRoute>
              }
            />
            <Route
              path="/schools"
              element={
                <ProtectedRoute>
                  <Schools />
                </ProtectedRoute>
              }
            />
            <Route
              path="/add-school"
              element={
                <ProtectedRoute>
                  <AddSchool />
                </ProtectedRoute>
              }
            />
            <Route
              path="/grade"
              element={
                <ProtectedRoute>
                  <Grades />
                </ProtectedRoute>
              }
            />
            <Route
              path="/add-grade"
              element={
                <ProtectedRoute>
                  <AddGrade />
                </ProtectedRoute>
              }
            />
            <Route
              path="/questions"
              element={
                <ProtectedRoute>
                  <Questions />
                </ProtectedRoute>
              }
            />
            <Route
              path="/add-question"
              element={
                <ProtectedRoute>
                  <AddQue />
                </ProtectedRoute>
              }
            />
            <Route
              path="/about"
              element={
                <ProtectedRoute>
                  <About />
                </ProtectedRoute>
              }
            />
            <Route
              path="/faq-management"
              element={
                <ProtectedRoute>
                  <FAQ />
                </ProtectedRoute>
              }
            />
            <Route
              path="/add-faq"
              element={
                <ProtectedRoute>
                  <AddFAQ />
                </ProtectedRoute>
              }
            />
            <Route
              path="/about"
              element={
                <ProtectedRoute>
                  <About />
                </ProtectedRoute>
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <ProtectedRoute>
                  <PrivacyPolicy />
                </ProtectedRoute>
              }
            />
            <Route
              path="/T&C"
              element={
                <ProtectedRoute>
                  <TAndC />
                </ProtectedRoute>
              }
            />
            <Route
              path="*"
              element={<Navigate to={isLoggedIn ? "/dashboard" : "/login"} />}
            />
          </Routes>
        </div>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <>
      <Toaster />
      <MainLayout />
    </>
  );
};

export default App;
