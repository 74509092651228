import { createSlice } from "@reduxjs/toolkit";
import { fetchHomeCounts } from "./thunk";

const countSlice = createSlice({
  name: "home",
  initialState: {
    total_home_counts: null,
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeCounts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchHomeCounts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.total_home_counts = action?.payload.data;
        console.log("action.payload===>",action.payload)
      })
      .addCase(fetchHomeCounts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default countSlice.reducer;
