import { createSlice } from '@reduxjs/toolkit';
import { addFAQ, fetchFAQs, updateFAQ, deleteFAQ } from './faqThunk'; // Import your thunks

// Define an initial state
const initialState = {
  faqs: [],
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null
};

// Create a slice of the Redux store
const faqSlice = createSlice({
  name: 'faqs',
  initialState,
  reducers: {
    // Define any synchronous actions here if needed
  },
  extraReducers: (builder) => {
    builder
      // Fetch FAQs
      .addCase(fetchFAQs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFAQs.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.faqs = action.payload.data; // Assuming payload has data
      })
      .addCase(fetchFAQs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload; // Assuming error payload
      })
      
    //   // Add FAQ
    //   .addCase(addFAQ.pending, (state) => {
    //     state.status = 'loading';
    //   })
    //   .addCase(addFAQ.fulfilled, (state, action) => {
    //     state.status = 'succeeded';
    //     state.faqs.push(action.payload); // Add the new FAQ to the list
    //   })
    //   .addCase(addFAQ.rejected, (state, action) => {
    //     state.status = 'failed';
    //     state.error = action.payload;
    //   })

    //   // Update FAQ
    //   .addCase(updateFAQ.pending, (state) => {
    //     state.status = 'loading';
    //   })
    //   .addCase(updateFAQ.fulfilled, (state, action) => {
    //     state.status = 'succeeded';
    //     const index = state.faqs.findIndex(faq => faq._id === action.payload._id);
    //     if (index !== -1) {
    //       state.faqs[index] = action.payload; // Update the FAQ in the list
    //     }
    //   })
    //   .addCase(updateFAQ.rejected, (state, action) => {
    //     state.status = 'failed';
    //     state.error = action.payload;
    //   })

    //   // Delete FAQ
    //   .addCase(deleteFAQ.pending, (state) => {
    //     state.status = 'loading';
    //   })
    //   .addCase(deleteFAQ.fulfilled, (state, action) => {
    //     state.status = 'succeeded';
    //     state.faqs = state.faqs.filter(faq => faq._id !== action.payload._id); // Remove the deleted FAQ
    //   })
    //   .addCase(deleteFAQ.rejected, (state, action) => {
    //     state.status = 'failed';
    //     state.error = action.payload;
    //   });
  }
});

export default faqSlice.reducer;
