import React, { useEffect } from "react";
import { useFormik } from "formik";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import "./Grade.css"; // Import the custom CSS file
import { addGrade, fetchGrades, updateGrade } from "./gradeThunk";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchCountries } from "../Countries/countryThunk";

export default function AddGrade() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { countries } = useSelector((state) => state.countries);

  console.log("location===>", location.state);
  // Formik setup
  const formik = useFormik({
    initialValues: {
      title: "",
      country: "",
      _id: "",
    },
    onSubmit: async (values) => {
      try {

 if(values._id){
    values._id = location.state._id;
    // Dispatch updateGrade action with form values
    const res = await dispatch(updateGrade(values));
    const response = await dispatch(fetchGrades());
    console.log("Dispatch updateGrade action with form values:", response);
    if (response?.payload?.status) {
      navigate("/grade");
    }
    

 }else{
    delete values._id
     // Dispatch addGrade action with form values
     const res = await dispatch(addGrade(values));
     const response = await dispatch(fetchGrades());
     console.log("Dispatch addGrade action with form values:", response);
     if (response?.payload?.status) {
       navigate("/grade");
     }

 }
      } catch (error) {
        console.error("Failed to submit form:", error);
      }
    },
  });

  // Fetch countries
  const getCountries = async () => {
    try {
      await dispatch(fetchCountries());
    } catch (error) {
      console.error("Failed to fetch countries:", error);
    }
  };
 
  useEffect(() => {
    getCountries();
  }, [dispatch]);
  useEffect(() => {
    if (location?.state) {
      const { title, country, _id } = location.state;
      if (title) formik.setFieldValue("title", title);
      if (country) formik.setFieldValue("country", country._id);
      if (_id) formik.setFieldValue("_id", _id);
    }
  }, []);

  const countriesOptions =
    countries?.map((country) => ({
      value: country._id,
      label: country.name,
    })) || [];

  return (
    <Container className="mt-5">
      <Row>
        <Col md={6} className="mx-auto">
          <h2 className="text-center">Add Grade</h2>
          <Form onSubmit={formik.handleSubmit} className="custom-form">
            <Form.Group controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                onChange={formik.handleChange}
                value={formik.values.title}
                placeholder="Enter title"
              />
            </Form.Group>
            <Form.Group controlId="country">
              <Form.Label>Country</Form.Label>
              <Form.Control
                as="select"
                name="country"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.country}
                isInvalid={formik.touched.country && formik.errors.country}
              >
                <option value="">Select a country</option>{" "}
                {/* Default option */}
                {countriesOptions.map((country) => (
                  <option key={country.value} value={country.value}>
                    {country.label}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {formik.touched.country && formik.errors.country}
              </Form.Control.Feedback>
            </Form.Group>
            <Button className="mt-2" variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
