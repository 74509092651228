import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Dashboard.css"; // Assuming you have a CSS file for custom styles
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeCounts } from "./thunk";
import toast from "react-hot-toast";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { total_home_counts, status } = useSelector((state) => state.home);
  const [homeCounts, setHomeCounts] = useState({});

  console.log("total_home_counts:", total_home_counts);

  const callCounts = async () => {
    try {
      const response = await dispatch(fetchHomeCounts());
      console.log("response:", response);
      if (response?.payload?.data?.code === 200) {
        setHomeCounts(response?.payload?.data?.data);
      } else {
        console.error(
          "Failed to fetch home counts:",
          response?.payload?.data?.message
        );
        toast.error("Failed to fetch counts");
      }
    } catch (error) {
      console.error("Failed to fetch counts:", error);
    }
  };
  useEffect(() => {
    callCounts();
  }, []);
  return (
    // <header className="header bg-gradient-primary pb-8 pt-5 pt-md-8">
    <div className="dashboard-container">
      <h1>Dashboard</h1>
      <div className="header-body">
        <Row>
          <Col xl={3} lg={6}>
            <Card className="card-stats mb-4 mb-xl-0">
              <Card.Body>
                <Row>
                  <Col>
                    <h5 className="card-title text-uppercase text-muted mb-0">
                      Users
                    </h5>
                    <span className="h2 font-weight-bold mb-0">
                      {homeCounts.users}
                    </span>
                  </Col>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                      <i className="fas fa-users"></i>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          {/* Repeat similar blocks for New users, Sales, and Performance */}
          <Col xl={3} lg={6}>
            <Card className="card-stats mb-4 mb-xl-0">
              <Card.Body>
                <Row>
                  <Col>
                    <h5 className="card-title text-uppercase text-muted mb-0">
                      Schools
                    </h5>
                    <span className="h2 font-weight-bold mb-0">
                      {homeCounts?.schools}
                    </span>
                  </Col>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                      <i className="fas fa-chart-pie"></i>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={3} lg={6}>
            <Card className="card-stats mb-4 mb-xl-0">
              <Card.Body>
                <Row>
                  <Col>
                    <h5 className="card-title text-uppercase text-muted mb-0">
                      Countries
                    </h5>
                    <span className="h2 font-weight-bold mb-0">
                      {homeCounts?.countries}
                    </span>
                  </Col>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                      <i className="fas fa-users"></i>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={3} lg={6}>
            <Card className="card-stats mb-4 mb-xl-0">
              <Card.Body>
                <Row>
                  <Col>
                    <h5 className="card-title text-uppercase text-muted mb-0">
                      Grades
                    </h5>
                    <span className="h2 font-weight-bold mb-0">
                      {homeCounts?.grades}
                    </span>
                  </Col>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                      <i className="fas fa-percent"></i>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
    // </header>
  );
};

export default Dashboard;
