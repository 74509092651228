import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Form, Spinner, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { deleteFAQ, fetchFAQs } from "./faqThunk"; // Import your thunks
import { FaEdit, FaTrash } from "react-icons/fa";

export default function FAQs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const { faqs, status, error, total_count } = useSelector((state) => state.faqs);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
console.log("faksdsdh",faqs)
  useEffect(() => {
    dispatch(fetchFAQs({ search, skip: currentPage, limit: itemsPerPage }));
  }, [dispatch, currentPage, search, itemsPerPage]);

  const removeFAQ = async (_id) => {
    try {
      await dispatch(deleteFAQ(_id));
      dispatch(fetchFAQs({ search, skip: currentPage, limit: itemsPerPage }));
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const editValuesSet = (item) => {
    navigate("/add-faq", { state: item });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  const totalItems = faqs?.total || 0;
  const totalPages = totalItems > 0 ? Math.ceil(totalItems / itemsPerPage) : 0;

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <h1>FAQs</h1>
        <div style={{ width: "270px" }}>
          <Form.Control
            onChange={handleSearch}
            value={search}
            size="lg"
            type="text"
            placeholder="Search here"
          />
        </div>
        <button className="button" variant="primary"  onClick={() => navigate("/add-faq")}>
          Add FAQ
        </button>
      </div>

      <Card className="mt-4">
        <Card.Header>
          <h5>FAQs Overview</h5>
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Question</th>
                <th>Answer</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {status === "loading" ? (
                <tr>
                  <td colSpan="4" className="text-center">
                    <Spinner animation="border" variant="primary" />
                  </td>
                </tr>
              ) : status === "succeeded" && faqs?.length > 0 ? (
                faqs?.map((item, i) => (
                  <tr key={item._id || i}>
                    <td>{i + 1}</td>
                    <td>{item.question}</td>
                    <td>{item.answer.slice(0,105)}...</td>
                    <td>
                      <div style={{ display: "flex", gap: "10px", cursor: "pointer" }}>
                        <FaEdit onClick={() => editValuesSet(item)} />
                        <FaTrash onClick={() => removeFAQ(item._id)} />
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">
                    Data Not Found!!
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          {/* Pagination Controls */}
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                <a
                  className="page-link"
                  href="#"
                  aria-label="Previous"
                  onClick={(e) => {
                    e.preventDefault();
                    if (currentPage > 1) {
                      handlePageChange(currentPage - 1);
                    }
                  }}
                >
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              {[...Array(totalPages)?.keys()]?.map((page) => (
                <li key={page + 1} className={`page-item ${currentPage === page + 1 ? "active" : ""}`}>
                  <a
                    className="page-link"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handlePageChange(page + 1);
                    }}
                  >
                    {page + 1}
                  </a>
                </li>
              ))}
              <li className="page-item">
                <a
                  className="page-link"
                  href="#"
                  aria-label="Next"
                  onClick={(e) => {
                    e.preventDefault();
                    if (currentPage < totalPages) {
                      handlePageChange(currentPage + 1);
                    }
                  }}
                >
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </Card.Body>
      </Card>
    </div>
  );
}
