import { createSlice } from '@reduxjs/toolkit';
import { fetchQuestions, addQuestion, updateQuestion, deleteQuestion } from './questionsThunk';

// Define an initial state
const initialState = {
  questions: [], // Array to hold questions data
  status: 'idle', // Status of the data fetching process
  error: null // Error message if any
};

// Create a slice of the Redux store
const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: { 
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuestions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchQuestions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.questions = action.payload.data; // Assuming data is in `data` field of response
      })
      .addCase(fetchQuestions.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload; // Assuming payload contains error information
      })
      .addCase(addQuestion.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addQuestion.fulfilled, (state, action) => {
        state.status = 'succeeded';  
      })
      .addCase(addQuestion.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;  
      })
      .addCase(updateQuestion.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateQuestion.fulfilled, (state, action) => {
        state.status = 'succeeded';
      
      })
      .addCase(updateQuestion.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload; 
      })
      .addCase(deleteQuestion.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteQuestion.fulfilled, (state, action) => {
        console.log("action.payload",action.payload)
        state.status = 'succeeded'; 
      })
      .addCase(deleteQuestion.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload; // Assuming payload contains error information
      });
  }
});

export default questionsSlice.reducer;
