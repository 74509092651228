import { createSlice } from '@reduxjs/toolkit';
import { addGrade, fetchGrades, updateGrade } from './gradeThunk';

// Define an initial state
const initialState = {
  grades: [],
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null
};



// Create a slice of the Redux store
const gradeSlice = createSlice({
  name: 'grades',
  initialState,
  reducers: {
    // Define any synchronous actions here
  },
  extraReducers: (builder) => {
    builder
     .addCase(fetchGrades.pending, (state,action) => {
        console.log('loading',action?.payload)

        state.status = 'loading';
      })
     .addCase(fetchGrades.fulfilled, (state,action) => {
        console.log('succeeded',action?.payload)
        state.status = 'succeeded';
        state.grades = action?.payload?.data
      })
     .addCase(fetchGrades.rejected, (state,action) => {
        console.log('failed',action?.payload)

        state.status = 'failed';
      })



      .addCase(updateGrade.pending, (state,action) => {
        console.log('loading',action?.payload)

        state.status = 'loading';
      })
     .addCase(updateGrade.fulfilled, (state,action) => {
        console.log('succeeded',action?.payload)
        state.status = 'succeeded';
        // state.grades = action?.payload?.data
      })
     .addCase(updateGrade.rejected, (state,action) => {
        console.log('failed',action?.payload)

        state.status = 'failed';
      })
      .addCase(addGrade.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addGrade.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // state.grades.push(action.payload);
      })
      .addCase(addGrade.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

  }
});
 
export default gradeSlice.reducer;
