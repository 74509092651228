import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Form, Alert } from 'react-bootstrap';
import './ChangePassword.css';
import { useDispatch } from 'react-redux';
import { changePassword } from './authThunk';

// Validation schema using Yup
const validationSchema = Yup.object({
  old_password: Yup.string().required('Old password is required'),
  password: Yup.string()
    .min(6, 'New password must be at least 6 characters')
    .required('New password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm new password is required'),
});

const ChangePassword = () => {
    const dispatch= useDispatch()
  const formik = useFormik({
    initialValues: {
      old_password: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit:async (values, { resetForm }) => {
    console.log('Form values:', values); 
    const res = await dispatch(changePassword(values));
    console.log("response=>>>",res)

      resetForm(); 
    },
  });

  return (
    <div className="change-password-form">
      <h2>Change Password</h2>
      {formik.errors.old_password || formik.errors.password || formik.errors.confirmPassword ? (
        <Alert variant="danger">
          {formik.errors.old_password && <div>{formik.errors.old_password}</div>}
          {formik.errors.password && <div>{formik.errors.password}</div>}
          {formik.errors.confirmPassword && <div>{formik.errors.confirmPassword}</div>}
        </Alert>
      ) : null}
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group controlId="formOldPassword">
          <Form.Label>Old Password</Form.Label>
          <Form.Control
            type="password"
            name="old_password"
            placeholder="Enter old password"
            value={formik.values.old_password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isInvalid={formik.touched.old_password && formik.errors.old_password}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.old_password}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formNewPassword">
          <Form.Label>New Password</Form.Label>
          <Form.Control
            type="password"
            name="password"
            placeholder="Enter new password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isInvalid={formik.touched.password && formik.errors.password}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.password}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formConfirmPassword">
          <Form.Label>Confirm New Password</Form.Label>
          <Form.Control
            type="password"
            name="confirmPassword"
            placeholder="Confirm new password"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isInvalid={formik.touched.confirmPassword && formik.errors.confirmPassword}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.confirmPassword}
          </Form.Control.Feedback>
        </Form.Group>
        <Button className='mt-2' variant="primary" type="submit">
          Change Password
        </Button>
      </Form>
    </div>
  );
};

export default ChangePassword;
