import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { base_url } from "../../constants";

// Define an asynchronous thunk for fetching questions
export const fetchQuestions = createAsyncThunk(
  "questions/getQuestions",
  async ({   skip, limit, search }, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${base_url}/questions?skip=${skip}&limit=${limit}&search=${search}`,
        {
          headers: {
            token,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Define an asynchronous thunk for adding a question
export const addQuestion = createAsyncThunk(
  "questions/addQuestion",
  async (questionData, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(`${base_url}/questions`, questionData, {
        headers: {
          "Content-Type": "application/json",
          token,
        },
      });
console.log("response",response)
      if (response.status ===200 ) {
        return response.data;
      } else {
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Define an asynchronous thunk for updating a question
export const updateQuestion = createAsyncThunk(
  "questions/updateQuestion",
  async (questionData, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        `${base_url}/questions/${questionData._id}/updateTargetAudience`,
        questionData,
        {
          headers: {
            "Content-Type": "application/json",
            token,
          },
        }
      );

      if (response.status ) {
        return response.data;
      } else {
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
export const editQuestion = createAsyncThunk(
    "questions/editQuestion",
    async (questionData, { rejectWithValue }) => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.put(
          `${base_url}/questions/${questionData._id}/edit`,
          questionData,
          {
            headers: {
              "Content-Type": "application/json",
              token,
            },
          }
        );
  
        if (response.status ) {
          return response.data;
        } else {
          throw new Error(`Error: ${response.status}`);
        }
      } catch (error) {
        return rejectWithValue(
          error.response ? error.response.data : error.message
        );
      }
    }
  );
// Define an asynchronous thunk for deleting a question
export const deleteQuestion = createAsyncThunk(
  "questions/deleteQuestion",
  async (questionId, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(`${base_url}/questions/${questionId}`, {
        headers: {
          token,
        },
      }); 
      if (response.status  ) {
        return response.data;
      } else {
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
