import React, { useEffect } from "react";
import { useFormik } from "formik";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import "./Countries.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addCountries,
  fetchCountries,
  updateCountries,
} from "../Countries/countryThunk";
import toast from "react-hot-toast";

export default function AddCountry() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  console.log("location===>", location.state);
  // Formik setup
  const formik = useFormik({
    initialValues: {
      name: "",
      _id: "",
    },
    onSubmit: async (values) => {
      try {
        if (values._id) {
          values._id = location.state._id;
          // Dispatch updateGrade action with form values
          const res = await dispatch(updateCountries(values));
          const response = await dispatch(fetchCountries({ search: "", skip: 0, limit: Infinity }));
          console.log(
            "Dispatch updateGrade action with form values:",
            response
          );
          if (response?.payload?.status) {
            navigate("/countries");
          }
        } else {
          delete values._id;
          // Dispatch addGrade action with form values
          const res = await dispatch(addCountries(values));
          console.log("Dispatch addGrade action with form values:", res);
          if (res?.payload?.code === 201) {
            toast.error(res?.payload?.message);
          } else {
            const response = await dispatch(fetchCountries({ search: "", skip: 0, limit: Infinity }));
            navigate("/countries");
          }

        }
      } catch (error) {
        console.error("Failed to submit form:", error);
      }
    },
  });

  // Fetch countries
  const getCountries = async () => {
    try {
      await dispatch(fetchCountries({ search: "", skip: 0, limit: Infinity }));
    } catch (error) {
      console.error("Failed to fetch countries:", error);
    }
  };

  useEffect(() => {
    getCountries();
  }, [dispatch]);
  useEffect(() => {
    if (location?.state) {
      const { name, _id } = location.state;
      if (name) formik.setFieldValue("name", name);
      if (_id) formik.setFieldValue("_id", _id);
    }
  }, []);

  return (
    <Container className="mt-5">
      <Row>
        <Col md={6} className="mx-auto">
          <h2 className="text-center">Add Country</h2>
          <Form onSubmit={formik.handleSubmit} className="custom-form">
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                placeholder="Enter name"
              />
            </Form.Group>

            <Button className="mt-2" variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
