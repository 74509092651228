import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { base_url } from "../../constants";

export const fetchCountries = createAsyncThunk("countries/getCountries", async ({ search="", skip, limit }) => {
  let token = localStorage.getItem("token");
  try {
    const response = await axios.get(`${base_url}/countries?skip=${skip}&limit=${limit}&search=${search}`, {
      headers: {
        token,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error, " main error")

    throw error;
  }
});

// Define an asynchronous thunk for adding a grade
export const addCountries = createAsyncThunk(
  "countries/addCountry",
  async (values, { rejectWithValue }) => {
    let token = localStorage.getItem("token");

    try {
      const response = await axios.post(`${base_url}/countries`, values, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token,
        },

      });

      return response.data;

    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Define an asynchronous thunk for updating a grade

export const updateCountries = createAsyncThunk(
  "countries/updateCountry",
  async (values, { rejectWithValue }) => {
    let token = localStorage.getItem("token");
    console.log("token:", token);
    try {
      const response = await axios.put(`${base_url}/countries/${values._id}`, values, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          token,
        },
      });

      return response.data;

    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Define an asynchronous thunk for deleting a grade

export const deleteCountries = createAsyncThunk(
  "countries/deleteCountry",
  async (_id, { rejectWithValue }) => {
    let token = localStorage.getItem("token");
    try {
      const response = await axios.delete(`${base_url}/countries/${_id}`, {
        headers: {
          token,
        },
      });

      return response.data;

    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
