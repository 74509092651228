import { configureStore } from "@reduxjs/toolkit";
import usersReducer from "./components/User/userSlice";
import authSlice from "./components/Login/authSlice";
import countSlice from "./components/Dashboard/slice";
import gradeReducer from "./components/Grades/gradeSlice";
import countriesSlice from "./components/Countries/countrySlice";
import schoolSlice from "./components/School/schoolSlice";
import questionsSlice from "./components/Questions/questionsSlice";
import faqsSlice from "./components/FAQ/faqSlice";

export const store = configureStore({
  reducer: {
    users: usersReducer,
    auth: authSlice,
    home: countSlice,
    grades: gradeReducer,
    countries: countriesSlice,
    schools: schoolSlice,
    questions: questionsSlice,
    faqs:faqsSlice
  },
});
