import axios from "axios";
import { base_url } from "../../constants";
import { createAsyncThunk } from "@reduxjs/toolkit";

// Define an async thunk
export const fetchHomeCounts = createAsyncThunk("home/counts", async () => {
    let token = localStorage.getItem("token");
    const data = await axios.get(`${base_url}/home_counts`, {
      headers: {
        token,
      },
    });
    
    return data;
  });