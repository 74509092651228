import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { base_url } from "../../constants";

export const fetchSchools = createAsyncThunk("schools/fetchSchools", async () => {
  let token = localStorage.getItem("token");
  try {
    const response = await axios.get(`${base_url}/schools`, {
      headers: {
        token,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
});

// Define an asynchronous thunk for adding a school
export const addSchool = createAsyncThunk(
  "schools/addSchool",
  async (schoolData, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    console.log("token:", token);
    try {
      const response = await axios.post(`${base_url}/schools`, schoolData, {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      });

      // Check for successful response
      if (response.status >= 200) {
        return response.data;
      } else {
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
// Define an asynchronous thunk for updating a school
export const updateSchool = createAsyncThunk(
  "schools/updateSchool",
  async (schoolData, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    console.log("token:", token);
    try {
      const response = await axios.put(
        `${base_url}/schools/${schoolData._id}`,
        schoolData,
        {
          headers: {
            "Content-Type": "application/json",
            token: `${token}`,
          },
        }
      );

      // Check for successful response
      if (response.status >= 200) {
        return response.data;
      } else {
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
// Define an asynchronous thunk for deleting a school
export const deleteSchool = createAsyncThunk(
  "schools/deleteSchool",
  async (schoolId, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    console.log("token:", token);
    try {
      const response = await axios.delete(`${base_url}/schools/${schoolId}`, {
        headers: {
          token: `${token}`,
        },
      });
      // Check for successful response
      if (response.status >= 200) {
        return response.data;
      } else {
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
