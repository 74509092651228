import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { deleteUser, fetchUsers } from "./userSlice";
import { Card, Table, Button, Spinner, Form } from "react-bootstrap";
import "./UsersList.css"; // Import custom CSS for additional styles
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

function Users() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { users, status, error } = useSelector((state) => state.users);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  useEffect(() => {
    dispatch(fetchUsers({ search, skip: currentPage, limit: itemsPerPage })).unwrap()
    .catch((error) => {
      console.log("I m in")
      if (error.code === 203) {
        localStorage.clear();
        navigate('/login'); // Navigate to login screen
      }
    });;

  }, [dispatch, currentPage, search, itemsPerPage]);

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  }
  const handleSelect = (event) => {
    const value = event.target.value;

    setCurrentPage(1);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalItems = users?.total || 0;
  const totalPages = totalItems > 0 ? Math.ceil(totalItems / itemsPerPage) : 0;
  const removeItem = async (_id) => {

    try {
      const res = await dispatch(deleteUser(_id))
      console.log("deleteUser", res)
      if (res.payload.data.code === 200) {
        toast.success("User Deleted successfully")
        dispatch(fetchUsers({ search, skip: currentPage, limit: itemsPerPage })).unwrap()
          .catch((error) => {
            console.log("I m in")
            if (error.code === 203) {
              localStorage.clear();
              navigate('/login'); // Navigate to login screen
            }
          });
      } else {
        toast.error("Failed to delete user")
      }
    } catch (error) {
      console.error("Failed to remove item:", error);


    }
  }
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <h1>Users</h1>
        <div style={{ width: "270px" }}>
          <Form.Control
            onChange={handleSearch}
            value={search}
            size="lg"
            type="text"
            placeholder="Search here"
          />
        </div>

      </div>
      {
        <Card className="mt-4">
          <Card.Header>
            <h5>Users Overview</h5>
          </Card.Header>
          <Card.Body>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Username</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {status === "loading" && (
                  <div className="loader-container">
                    <Spinner animation="border" variant="primary" />
                  </div>
                )}
                {status === "failed" && <p>Error: {error}</p>}

                {status === "succeeded" &&
                  users?.data?.map((user, index) => (
                    <tr key={user._id}>
                      <td>{user._id}</td>
                      <td>{`${user.firstName} ${user.lastName}`}</td>
                      <td>{user.email}</td>
                      <td>{user.role}</td>
                      <td>{user.username}</td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <FaEdit
                            onClick={() => {
                              navigate("/edit", { state: user });
                            }}
                          />
                          {/* <FaEye /> */}
                          <FaTrash aria-label="Disable" onClick={() => removeItem(user._id)} />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            {/* Pagination Controls */}
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className="page-item">
                  <a
                    className="page-link"
                    href="#"
                    aria-label="Previous"
                    onClick={(e) => {
                      e.preventDefault();
                      if (currentPage > 1) {
                        handlePageChange(currentPage - 1);
                      }
                    }}
                  >
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                {[...Array(totalPages)?.keys()]?.map(page => (
                  <li key={page + 1} className={`page-item ${currentPage === page + 1 ? "active" : ""}`}>
                    <a
                      className="page-link"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handlePageChange(page + 1);
                      }}
                    >
                      {page + 1}
                    </a>
                  </li>
                ))}
                <li className="page-item">
                  <a
                    className="page-link"
                    href="#"
                    aria-label="Next"
                    onClick={(e) => {
                      e.preventDefault();
                      if (currentPage < totalPages) {
                        handlePageChange(currentPage + 1);
                      }
                    }}
                  >
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </Card.Body>
        </Card>
      }
    </div>
  );
}

export default Users;
