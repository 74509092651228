import axios from "axios";
import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { base_url } from "../constants";

const ProtectedRoute = ({ children }) => {
  const router = useNavigate();
  const isLoggedIn = !!localStorage.getItem("token");

  useEffect(() => {
    (async () => {
      let token = localStorage.getItem("token");
      const { data } = await axios.get(base_url + "/refresh-token", {
        headers: {
          token,
        },
      });
      if (!data.status) {
        localStorage.clear();
        router('/login')
      }
    })()
  }, [])

  return isLoggedIn ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
