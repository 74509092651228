import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Profile.css";
import img from "../../assets/img/user-avatar.jpg";
import { Button, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
const Profile = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [show, setShow] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });
  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h1>Profile </h1>
          <button onClick={() => setShow(true)}>Edit </button>
        </div>

        <div className="user-profile">
          <div className="profile-header">
            <img
              src={img}
              alt={`${user?.firstName}'s profile`}
              className="profile-picture"
            />
            <h1 className="user-name">{user?.firstName}</h1>
            <h3 className="user-email"> Email : {user?.email}</h3>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                onChange={formik.handleChange}
                value={formik.values.firstName}
                name="firstName"
                type="text"
                placeholder="First Name"
              />
            </Form.Group>
            <Form.Group controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                onChange={formik.handleChange}
                value={formik.values.lastName}
                name="lastName"
                type="text"
                placeholder="Last Name"
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control  onChange={formik.handleChange}
                value={formik.values.email}
                name="email" type="email" placeholder="Email" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="primary" type="submit" onClick={formik.handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Profile;
