import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { base_url } from "../../constants";

export const fetchGrades = createAsyncThunk(
  "grades/getGrades",
  async ({ country, skip, limit, search }) => {
    let token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${base_url}/grades?country=${country}&skip=${skip}&limit=${limit}&search=${search}`,
        {
          headers: {
            token,
          },
        }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Define an asynchronous thunk for adding a grade
export const addGrade = createAsyncThunk(
  "grades/addGrade",
  async (gradeData, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    console.log("token:", token);
    try {
      const response = await axios.post(`${base_url}/grades`, gradeData, {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      });

      // Check for successful response
      if (response.status >= 200) {
        return response.data;
      } else {
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
// Define an asynchronous thunk for updating a grade
export const updateGrade = createAsyncThunk(
  "grades/updateGrade",
  async (gradeData, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    console.log("token:", token);
    try {
      const response = await axios.put(
        `${base_url}/grades/${gradeData._id}`,
        gradeData,
        {
          headers: {
            "Content-Type": "application/json",
            token: `${token}`,
          },
        }
      );

      // Check for successful response
      if (response.status >= 200) {
        return response.data;
      } else {
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
// Define an asynchronous thunk for deleting a grade
export const deleteGrade = createAsyncThunk(
  "grades/deleteGrade",
  async (gradeId, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    console.log("token:", token);
    try {
      const response = await axios.delete(`${base_url}/grades/${gradeId}`, {
        headers: {
          token: `${token}`,
        },
      });
      // Check for successful response
      if (response.status >= 200) {
        return response.data;
      } else {
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
