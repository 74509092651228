import { createSlice } from '@reduxjs/toolkit'; 
import { addSchool, fetchSchools, updateSchool } from './schoolThunk';

// Define an initial state
const initialState = {
  schools: [],
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null
};



// Create a slice of the Redux store
const schoolSlice = createSlice({
  name: 'schools',
  initialState,
  reducers: {
    // Define any synchronous actions here
  },
  extraReducers: (builder) => {
    builder
     .addCase(fetchSchools.pending, (state,action) => {
        console.log('loading',action?.payload)

        state.status = 'loading';
      })
     .addCase(fetchSchools.fulfilled, (state,action) => {
        console.log('succeeded',action?.payload)
        state.status = 'succeeded';
        state.schools = action?.payload?.data
      })
     .addCase(fetchSchools.rejected, (state,action) => {
        console.log('failed',action?.payload)

        state.status = 'failed';
      })



      .addCase(updateSchool.pending, (state,action) => {
        console.log('loading',action?.payload)

        state.status = 'loading';
      })
     .addCase(updateSchool.fulfilled, (state,action) => {
        console.log('succeeded',action?.payload)
        state.status = 'succeeded';
        // state.schools = action?.payload?.data
      })
     .addCase(updateSchool.rejected, (state,action) => {
        console.log('failed',action?.payload)

        state.status = 'failed';
      })
      .addCase(addSchool.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addSchool.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // state.schools.push(action.payload);
      })
      .addCase(addSchool.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

  }
});
 
export default schoolSlice.reducer;
