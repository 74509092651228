import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import "./Questions.css"; // Import the custom CSS file if needed
import {
  addQuestion,
  updateQuestion,
  fetchQuestions,
  editQuestion,
} from "./questionsThunk";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchCountries } from "../Countries/countryThunk";

export default function AddQuestion() {
  const [imgPic, setImgPic] = useState("");
  const [imgPicUploadMsg, setImgPicUploadMsg] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // Formik setup
  const formik = useFormik({
    initialValues: {
      text: "",
      isRestricted: false,
      targetAudience: "Everyone",
      isPaid: false,
      _id: "",
      pic: "",
    },
    onSubmit: async (values) => {
      try {
        if (values._id) {
          // Update existing question
          const res = await dispatch(editQuestion(values));
          const response = await dispatch(fetchQuestions());
          console.log(
            "Dispatch updateQuestion action with form values:",
            response
          );
          navigate("/questions");
        } else {
          // Add new question
          delete values._id;
          const res = await dispatch(addQuestion(values));
          const response = await dispatch(fetchQuestions());
          console.log("Dispatch addQuestion action with form values:", res);
          if (res?.payload?.status) {
            navigate("/questions");
          }
        }
      } catch (error) {
        console.error("Failed to submit form:", error);
      }
    },
  });

  useEffect(() => {
    if (location?.state) {
      const { text, isRestricted, targetAudience, isPaid, pic, _id } =
        location.state;
      if (text) formik.setFieldValue("text", text);
      if (isRestricted) formik.setFieldValue("isRestricted", isRestricted);
      if (targetAudience)
        formik.setFieldValue("targetAudience", targetAudience);
      if (isPaid) formik.setFieldValue("isPaid", isPaid);
      if (isPaid) formik.setFieldValue("pic", pic);
      if (_id) formik.setFieldValue("_id", _id);
      setImgPic(pic)
    }
  }, [location.state]);

  const handleImageUpload = async (e) => {
    let formData = new FormData();
    const img = e.target.files[0];
    formData.append("profile_pic", img);
    try {
      const response = await fetch("http://localhost:3000/user/v1/upload", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Upload successful:", result);
      if (result?.status) {
        formik.setFieldValue("pic", result?.data?.filename);
        setImgPic(result?.data?.filename);
        setImgPicUploadMsg(result?.message);
      }
      setImgPic();
    } catch (error) {
      console.error("Failed to upload image:", error);
    }
  };
  const targetAudienceOptions = (selectedValue) => {
    // Array of option values and labels
    const options = [
      { value: "", label: "Select Target Audience" },
      { value: "Everyone", label: "Everyone" },
      { value: "My School", label: "My School" },
      { value: "My Grade", label: "My Grade" },
      { value: "Girls", label: "Girls" },
      { value: "Boys", label: "Boys" },
    ];

    return (
      <>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </>
    );
  };
  return (
    <Container className="mt-5">
      <Row>
        <Col md={6} className="mx-auto">
          <h2 className="text-center">Add Question</h2>
          <Form onSubmit={formik.handleSubmit} className="custom-form">
            <Form.Group controlId="pic">
              <Form.Label>Question Image:</Form.Label>
              <Form.Control
                type="file"
                onChange={handleImageUpload}
                value={imgPic}
                placeholder="Enter question text"
              />
            </Form.Group>
            {imgPic && <p>{imgPic}</p>}
            {imgPicUploadMsg && (
              <p style={{ color: "green" }}>{imgPicUploadMsg}</p>
            )}

            <Form.Group controlId="text">
              <Form.Label>Question Text:</Form.Label>
              <Form.Control
                type="text"
                name="text"
                onChange={formik.handleChange}
                value={formik.values.text}
                placeholder="Enter question text"
              />
            </Form.Group>

            <Form.Group controlId="targetAudience">
              <Form.Label>Target Audience:</Form.Label>
              <Form.Control
                as="select"
                name="targetAudience"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.targetAudience}
                isInvalid={
                  formik.touched.targetAudience && formik.errors.targetAudience
                }
              >
                {targetAudienceOptions()}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {formik.touched.targetAudience && formik.errors.targetAudience}
              </Form.Control.Feedback>
            </Form.Group>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <Form.Group controlId="isPaid">
                <Form.Check
                  type="checkbox"
                  name="isPaid"
                  label="Is Paid"
                  checked={formik.values.isPaid}
                  onChange={formik.handleChange}
                />
              </Form.Group>
              <Form.Group controlId="isRestricted">
                <Form.Check
                  type="checkbox"
                  name="isRestricted"
                  label="Is Restricted"
                  checked={formik.values.isRestricted}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </div>
            <Button className="mt-2" variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
