import React, { useEffect } from "react";
import { useFormik } from "formik";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { addSchool, fetchSchools, updateSchool } from "./schoolThunk";
import { fetchCountries } from "../Countries/countryThunk";

export default function AddSchool() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { countries } = useSelector((state) => state.countries);

  const formik = useFormik({
    initialValues: {
      name: "",
      address: "",
      country: "",
      _id: "",
    },
    onSubmit: async (values) => {
      try {
        if (values._id) {
          values._id = location.state._id;
          const res = await dispatch(updateSchool(values));         
          
          if (res?.payload?.status) {
            navigate("/schools");
            const response = await dispatch(fetchSchools());
          }
        } else {
          delete values._id; 
          const res = await dispatch(addSchool(values));
          const response = await dispatch(fetchSchools());           
          if (res?.payload?.status) {
            navigate("/schools");
            const response = await dispatch(fetchSchools());
          }
        }
      } catch (error) {
        console.error("Failed to submit form:", error);
      }
    },
  });

  // Fetch Schools
  const getSchools = async () => {
    try {
      await dispatch(fetchSchools());
    } catch (error) {
      console.error("Failed to fetch Schools:", error);
    }
  };
  // Fetch countries
  const getCountries = async () => {
    try {
      await dispatch(fetchCountries());
    } catch (error) {
      console.error("Failed to fetch countries:", error);
    }
  };
  const countriesOptions =
    countries?.map((country) => ({
      value: country._id,
      label: country.name,
    })) || [];
  useEffect(() => {
    getSchools();
    getCountries()
  }, [dispatch]);
  useEffect(() => {
    if (location?.state) {
      const { name, address, _id,country } = location.state;
      if (name) formik.setFieldValue("name", name);
      if (address) formik.setFieldValue("address", address);
      if (country) formik.setFieldValue("country", country._id);
      if (_id) formik.setFieldValue("_id", _id);
    }
  }, []);

 

  return (
    <Container className="mt-5">
      <Row>
        <Col md={6} className="mx-auto">
          <h2 className="text-center">Add school</h2>
          <Form onSubmit={formik.handleSubmit} className="custom-form">
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                placeholder="Enter name"
              />
            </Form.Group>
            <Form.Group controlId="address">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                onChange={formik.handleChange}
                value={formik.values.address}
                placeholder="Enter address"
              />
            </Form.Group>


            <Form.Group controlId="country">
              <Form.Label>Country</Form.Label>
              <Form.Control
                as="select"
                name="country"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.country}
                isInvalid={formik.touched.country && formik.errors.country}
              >
                <option value="">Select a country</option> {/* Default option */}
                {countriesOptions.map((country) => (
                  <option key={country.value} value={country.value}>
                    {country.label}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {formik.touched.school && formik.errors.school}
              </Form.Control.Feedback>
            </Form.Group>
          
            <Button className="mt-2" variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
