import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { base_url } from "../../constants";
import axios from "axios";

// Define an async thunk
export const fetchUsers = createAsyncThunk(
  "users/get_users",
  async ({ search, skip, limit }, { rejectWithValue }) => {
    let token = localStorage.getItem("token");
    const response = await fetch(
      `${base_url}/get_users?skip=${skip}&limit=${limit}&search=${search}`,
      {
        headers: { token },
      }
    );
    const data = await response.json();
    if (data.code === 203) {
      // Trigger navigation to login
      return rejectWithValue({ code: 203 });
    }
    return data;
  }
);

// Define an asynchronous thunk for updating a user
export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (userData, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    console.log("token:", userData);
    try {
      const response = await axios.post(
        `${base_url}/add_edit_user`,
        userData,
        {
          headers: {
            token: `${token}`,
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// Define an asynchronous thunk for deleting a user

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (userId, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    console.log("token:", userId);
    try {
      const response = await axios.delete(`${base_url}/delete_user/${userId}`, {
        headers: {
          token: `${token}`,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState: {
    users: [],
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        console.log("action.payload===>", action.payload)
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.users = action.payload.data;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default usersSlice.reducer;
