import { createSlice } from '@reduxjs/toolkit'; 
import { addCountries, fetchCountries } from './countryThunk';

// Define an initial state
const initialState = {
    countries: [],
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null
};



// Create a slice of the Redux store
const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    // Define any synchronous actions here
  },
  extraReducers: (builder) => {
    builder
     .addCase(fetchCountries.pending, (state,action) => {
        console.log('loading',action?.payload)

        state.status = 'loading';
      })
     .addCase(fetchCountries.fulfilled, (state,action) => {
        console.log('succeeded',action?.payload)
        state.status = 'succeeded';
        state.countries = action?.payload?.data?.countries
      })
     .addCase(fetchCountries.rejected, (state,action) => {
        console.log('failed',action)

        state.status = 'failed';
      })
      
      .addCase(addCountries.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addCountries.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.countries.push(action.payload);
      })
      .addCase(addCountries.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  }
});
 
export default countriesSlice.reducer;
