import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { base_url } from "../../constants";

// Fetch FAQs
export const fetchFAQs = createAsyncThunk(
  "faqs/getFAQs",
  async ({ skip, limit, search }) => {
    let token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${base_url}/faqs?skip=${skip}&limit=${limit}&search=${search}`,
        {
          headers: {
            token,
          },
        }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Add FAQ
export const addFAQ = createAsyncThunk(
  "faqs/addFAQ",
  async (faqData, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(`${base_url}/faqs`, faqData, {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      });

      if (response.status >= 200) {
        return response.data;
      } else {
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Update FAQ
export const updateFAQ = createAsyncThunk(
  "faqs/updateFAQ",
  async (faqData, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        `${base_url}/faqs/${faqData._id}`,
        faqData,
        {
          headers: {
            "Content-Type": "application/json",
            token: `${token}`,
          },
        }
      );

      if (response.status >= 200) {
        return response.data;
      } else {
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Delete FAQ
export const deleteFAQ = createAsyncThunk(
  "faqs/deleteFAQ",
  async (faqId, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(`${base_url}/faqs/${faqId}`, {
        headers: {
          token: `${token}`,
        },
      });

      if (response.status >= 200) {
        return response.data;
      } else {
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
