import React, { useEffect, useState } from "react";
import { fetchUsers } from "../User/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Form, Spinner, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { deleteGrade, fetchGrades } from "./gradeThunk";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { fetchCountries } from "../Countries/countryThunk";

const sampleCountries = [
  { country: "United States", isEnabled: true, title: "Harvard University" },
  { country: "Canada", isEnabled: true, title: "University of Toronto" },
  {
    country: "United Kingdom",
    isEnabled: false,
    title: "University of Oxford",
  },
];

export default function Grades() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [country, setCountry] = useState("");
  const { grades, status, error, total_count } = useSelector(
    (state) => state.grades
  );
  const { countries } = useSelector((state) => state.countries);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  console.log("fetchGrades=>", grades);
  useEffect(() => {
    dispatch(fetchGrades({ country, search,skip: currentPage, limit: itemsPerPage }));
    dispatch(fetchCountries());
  }, [dispatch, country, currentPage, search,itemsPerPage]);
  const removeGrade = async (_id) => {
    try {
      await dispatch(deleteGrade(_id));
      dispatch(
        fetchGrades({ country, search,skip: currentPage, limit: itemsPerPage })
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const editValuesSet = (item) => {
    navigate("/add-grade", { state: item });
  };
  const countriesOption = countries.map((country) => {
    return (
      <option key={country._id} value={country._id}>
        {country.name}
      </option>
    );
  });
  const handleSelect = (event) => {
    const value = event.target.value;
    setCountry(value);
    setCurrentPage(1);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

const handleSearch = (event)=>{
  setSearch(event.target.value);
  setCurrentPage(1);
}

const totalItems = grades?.total || 0;
const totalPages = totalItems > 0 ? Math.ceil(totalItems / itemsPerPage) : 0;
 
  return (
    <div className="">
      
        <div className="d-flex justify-content-between align-items-center mb-5">
          <h1>Grades</h1>
          <div style={{width:"270px"}}>
          <Form.Control
          onChange={handleSearch}
          value={search}
          size="lg"
          type="text"
          placeholder="Search here"
        />

          </div>
          <button className="button" variant="primary" onClick={() => navigate("/add-grade")}>
            Add Grade
          </button>
        </div>
      

      <Card className="mt-4">
        <Card.Header>
          <h5>Grades Overview</h5>
          <select
            class="custom-select"
            value={country}
            onChange={handleSelect}
            data-value=""
          >
            <option value="">Select Country</option>
            {countriesOption}
          </select>
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Title</th>
                <th>Country</th>
                {/* <th>Status</th> */}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {status === "loading" ? (
                <div
                  colSpan="4"
                  className="d-flex justify-content-center align-items-center"
                >
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : status === "succeeded" && grades?.grades?.length > 0 ? (
                grades.grades.map((item, i) => (
                  <tr key={item._id || i}>
                    <td>{i + 1}</td>
                    <td>{item.title}</td>
                    <td>
                      {item.country?.name?.charAt(0).toUpperCase() +
                        item.country?.name?.slice(1)}
                    </td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          cursor: "pointer",
                        }}
                      >
                        <FaEdit onClick={() => editValuesSet(item)} />
                        {/* <FaEye /> */}
                        <FaTrash onClick={() => removeGrade(item._id)} />
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">
                    Data Not Found!!
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          {/* Pagination Controls */}
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                <a
                  className="page-link"
                  href="#"
                  aria-label="Previous"
                  onClick={(e) => {
                    e.preventDefault();
                    if (currentPage > 1) {
                      handlePageChange(currentPage - 1);
                    }
                  }}
                >
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              {[...Array(totalPages)?.keys()]?.map(page => (
                <li key={page + 1} className={`page-item ${currentPage === page + 1 ? "active" : ""}`}>
                  <a
                    className="page-link"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handlePageChange(page + 1);
                    }}
                  >
                    {page + 1}
                  </a>
                </li>
              ))}
              <li className="page-item">
                <a
                  className="page-link"
                  href="#"
                  aria-label="Next"
                  onClick={(e) => {
                    e.preventDefault();
                    if (currentPage < totalPages) {
                      handlePageChange(currentPage + 1);
                    }
                  }}
                >
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </Card.Body>
      </Card>
    </div>
  );
}
