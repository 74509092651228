import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Form, Spinner, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  fetchQuestions,
  deleteQuestion,
  updateQuestion,
} from "./questionsThunk";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { base_url, base_url_img } from "../../constants";

export default function Questions() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const { questions, status, error, total_count } = useSelector(
    (state) => state.questions
  );

  useEffect(() => {
    dispatch(
      fetchQuestions({ search, skip: currentPage, limit: itemsPerPage })
    );
  }, [dispatch, search, currentPage, itemsPerPage]);

  const removeQuestion = async (id) => {
    try {
      const res = await dispatch(deleteQuestion(id));
      dispatch(
        fetchQuestions({ search, skip: currentPage, limit: itemsPerPage })
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const editValuesSet = (item) => {
    navigate("/add-question", { state: item });
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalItems = questions?.total || 0;
  const totalPages = totalItems > 0 ? Math.ceil(totalItems / itemsPerPage) : 0;

  const targetAudienceOptions = () => {
    // Array of option values and labels
    const options = [
      { value: "", label: "Select Target Audience" },
      { value: "Everyone", label: "Everyone" },
      { value: "My School", label: "My School" },
      { value: "My Grade", label: "My Grade" },
      { value: "Girls", label: "Girls" },
      { value: "Boys", label: "Boys" },
    ];

    return (
      <>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </>
    );
  };

  const handleUpdateTargetAudience = async (event, item) => {
    const updatedQuestion = {
      targetAudience: event.target.value,
      _id: item._id,
    };

    try {
      const res = await dispatch(updateQuestion(updatedQuestion));
      await dispatch(
        fetchQuestions({ search, skip: currentPage, limit: itemsPerPage })
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <h1>Questions</h1>
        <div style={{ width: "270px" }}>
          <Form.Control
            onChange={handleSearch}
            value={search}
            size="lg"
            type="text"
            placeholder="Search here"
          />
        </div>
        <button className="button" variant="primary" onClick={() => navigate("/add-question")}>
          Add Question
        </button>
      </div>

      <Card className="mt-4">
        <Card.Header>
          <h5>Questions Overview</h5>
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Pic</th>
                <th>Text</th>
                <th>Target Audience</th>
                <th>Paid</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {status === "loading" ? (
                <tr>
                  <td colSpan="4" className="text-center">
                    <Spinner animation="border" variant="primary" />
                  </td>
                </tr>
              ) : status === "succeeded" && questions?.questions?.length > 0 ? (
                questions?.questions?.map((item, i) => (
                  <tr key={item._id || i}>
                    <td>{i + 1}</td>
                    <td>
                      <img
                        src={`${base_url_img}/uploads/${item.pic}`}
                        alt="Image"
                        style={{
                          width: "100px",
                          height: "auto",
                          border: "1px solid #ccc",
                        }}
                        onError={(e) => {
                          e.target.onerror = null; 
                          e.target.src =[`${base_url_img}/uploads/smile.png`]
                        }}
                      />
                    </td>

                    <td>{item.text}</td>
                    <td>
                      <select
                        value={item.targetAudience}
                        onChange={(e) => handleUpdateTargetAudience(e, item)}
                      >
                        {targetAudienceOptions()}
                      </select>
                    </td>

                    <td>{item.isPaid ? "Paid" : "Unpaid"}</td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          cursor: "pointer",
                        }}
                      >
                        <FaEdit onClick={() => editValuesSet(item)} />
                        {/* <FaEye /> */}
                        <FaTrash onClick={() => removeQuestion(item._id)} />
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">
                    Data Not Found!!
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          {/* Pagination Controls */}
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                <a
                  className="page-link"
                  href="#"
                  aria-label="Previous"
                  onClick={(e) => {
                    e.preventDefault();
                    if (currentPage > 1) {
                      handlePageChange(currentPage - 1);
                    }
                  }}
                >
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              {[...Array(totalPages)?.keys()]?.map((page) => (
                <li
                  key={page + 1}
                  className={`page-item ${
                    currentPage === page + 1 ? "active" : ""
                  }`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handlePageChange(page + 1);
                    }}
                  >
                    {page + 1}
                  </a>
                </li>
              ))}
              <li className="page-item">
                <a
                  className="page-link"
                  href="#"
                  aria-label="Next"
                  onClick={(e) => {
                    e.preventDefault();
                    if (currentPage < totalPages) {
                      handlePageChange(currentPage + 1);
                    }
                  }}
                >
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </Card.Body>
      </Card>
    </div>
  );
}
