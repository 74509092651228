import { createSlice } from "@reduxjs/toolkit";
import { changePassword, login } from "./authThunk";
import toast from "react-hot-toast";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {
    logout: (state) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
     .addCase(changePassword.pending, (state) => { 
      state.status = "loading";
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;                      
        state.user = action.payload.data;
        toast.success("Password changed successfully.");

      })
      .addCase(changePassword.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        toast.error(action.error.message);
      })
      .addCase(login.pending, (state) => {
        state.status = "loading";
      })
      .addCase(login.fulfilled, (state, action) => {
        console.log("action.payload.data", action.payload);
        if (action?.payload?.code===200) {
          localStorage.setItem("token", action.payload.data.token);        
          localStorage.setItem("user", JSON.stringify(action.payload.data));        
          state.user = action.payload.data;
          state.status = "succeeded";
          state.error = null;

          toast.success("Login successfully.");
        } else {
          state.status = "failed";
          state.error = "Invalid credentials";
          toast.error(action.payload.message);
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
